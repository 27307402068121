import style from './Chat.module.css'
import Icon from '../../../assets/svgs/Icon'
import { useState } from 'react'
import UploadModal from '../files/UploadModal'
import ChatInteface from './ChatInterface'
import ChatInput from './ChatInput'
import useDraggable from '../../../hooks/useDraggable'
import { useFilesContext } from '../../../contexts/FilesContext'
import { QuickchatData } from '../../../dummyData'
import DropZoneIcon from '../../../assets/svgs/DropIcon'
import { useHistoryContext } from '../../../contexts/HistoryContext'

const ChatBig = () => {
  const [openUpload, setOpenUpload] = useState(false)
  const [formData, setFormData] = useState({ message: '' })
  const [loading, setLoading] = useState(false)

const {addRequest, addResponse,history }= useHistoryContext()
  const { draggable, onDragStart, onDragEnd, isDragging } = useDraggable()
  const { addFile, files } = useFilesContext()
  const handleDrop = (e) => {
    e.preventDefault()
    const data = e.dataTransfer.getData('application/json')
    const item = JSON.parse(data)
    addFile(item)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }
  const handleResponses = async (message) => {
    setLoading(true)
addRequest(message)

    try {
      const response = await fetch('https://chimpknows.com/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message })
      })

      if (response.ok) {
        const responseData = await response.json()
        const responseMessage = responseData.response.join('\n\n') 
        addResponse(responseMessage)
        setLoading(false)
      } else {
        console.error('Response not OK:', response.statusText)
      }
    } catch (error) {
      console.error('Error submitting form:', error)
    } 
  }
  return (
    <>
      {history.length > 0 ? (
        <ChatInteface  smallInterface={false} history={history} loading={loading} handleResponses={handleResponses}        setOpenUpload={setOpenUpload}
        openUpload={openUpload}/>
        
      ) : (
        <div
          className={style.bigContainer}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          {!isDragging ? (
            <>
              {' '}
              <div className={style.contentContainer}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '20px'
                  }}
                >
                  <div className={style.icon}>
                    <Icon height={'60px'} width={'60px'} color={'#432C5B'} />
                  </div>{' '}
                  <div>
                    <p className={style.contentTitle}>Welcome back User! 👋</p>
                    <p className={style.contentSubTitle}>
                      Here are some suggestions for you to get the ball rolling
                      with me:
                    </p>
                  </div>{' '}
                  <div className={style.cardContainer}>
                    {QuickchatData.map((card, index) => (
                     <div className={style.card} key={index} onClick={() => handleResponses(card.title)}>
                     <p className={style.cardTitle}>{card.title}</p>
                   </div>
                    ))}
                  </div>
                </div>
                <div className={style.bigInput}>
                  <ChatInput
                    setOpenUpload={setOpenUpload}
                    openUpload={openUpload}
                    handleResponses={handleResponses}
                    setFormData={setFormData}
                    formData={formData}
                  />
                </div>{' '}
              </div>
            </>
          ) : (
            <>
              <div className={style.dropZone}>
                <DropZoneIcon />
                <p className={style.dropZoneText}>
                  Drag & drop items from dashboard to discuss with Chimp
                </p>
              </div>
            </>
          )}
        </div>
      )}
      {openUpload && (
        <UploadModal openUpload={openUpload} setOpenUpload={setOpenUpload} />
      )}
    </>
  )
}
export default ChatBig
