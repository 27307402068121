
import style from './Feed.module.css'
import Icon from '../../../assets/svgs/Icon'
import SaveIcon from '../../../assets/svgs/Save'
import DragIcon from '../../../assets/svgs/Drag'
import { useEffect, useRef, useState } from 'react'
import BagIcon from '../../../assets/svgs/Bag'
import ContactIcon from '../../../assets/svgs/ContactDB'
import CourseIcon from '../../../assets/svgs/CourseDB'
import EventIcon from '../../../assets/svgs/EventDB'
import NewsIcon from '../../../assets/svgs/NewsDB'
import { useFilesContext } from '../../../contexts/FilesContext'
import useDraggable from '../../../hooks/useDraggable'
import FileUploadedIcon from '../../../assets/svgs/FileUploaded'
import CheckMarkIcon from '../../../assets/svgs/Checkmark'
console.log('Rendering FeedCard for each feedItem');
const FeedCard = ({ feedItem, index, chatTab, events }) => {
  const [isSaved, setIsSaved] = useState(false)
  const [expanded, setExpanded] = useState(null)
  const { addFile, files } = useFilesContext()
  const ref = useRef(null)
  const exsistingFile = files.some((item) => item.id === feedItem.id)
  const { draggable, onDragStart, onDragEnd, isDragging } = useDraggable()

  useEffect(() => {
    console.log('FeedCard rendered with feedItem:', feedItem);
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setExpanded(null)
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [feedItem])

  const handleClickAdd = (event) => {
    event.stopPropagation()
    console.log('Add to chat clicked for feedItem:', feedItem);
    addFile(feedItem)
  }

  const handleSave = (event) => {
    event.stopPropagation()
    setIsSaved(!isSaved)
    console.log('Save toggled for feedItem:', feedItem, 'isSaved:', !isSaved);
  }

  const handleClick = (event) => {
    event.stopPropagation()
  }

  const handleExpand = (value) => {
    setExpanded(expanded === value ? null : value)
    console.log('Card expanded state toggled for index:', index, 'Expanded:', expanded === value ? null : value);
  }

  const checkTime = (dayPosted) => {
    const currentDate = new Date()
    const postedDate = new Date(dayPosted)
    const [datePart, timePart] = dayPosted.split(' ')

    if (
      currentDate.getDate() === postedDate.getDate() &&
      currentDate.getMonth() === postedDate.getMonth() &&
      currentDate.getFullYear() === postedDate.getFullYear() &&
      currentDate.getHours() === postedDate.getHours()
    ) {
      return 'Just Now'
    }

    if (
      currentDate.getDate() === postedDate.getDate() &&
      currentDate.getMonth() === postedDate.getMonth() &&
      currentDate.getFullYear() === postedDate.getFullYear()
    ) {
      return timePart
    }

    return datePart
  }

  const feedIcon = () => {
    switch (feedItem.category) {
      case 'Jobs':
        return (
          <div
            className={style.iconContainer}
            style={{ background: '#A7CAFF' }}
          >
            <BagIcon />
          </div>
        )
      case 'Contacts':
        return (
          <div
            className={style.iconContainer}
            style={{ background: '#F79DFF' }}
          >
            <ContactIcon />
          </div>
        )
      case 'Chimp':
        return (
          <div
            className={style.iconContainer}
            style={{ background: '#432C5B' }}
          >
            <Icon />
          </div>
        )
      case 'Courses':
        return (
          <div
            className={style.iconContainer}
            style={{ background: '#FFC092' }}
          >
            <CourseIcon />
          </div>
        )
      case 'Events':
        return (
          <div
            className={style.iconContainer}
            style={{ background: '#78DEB9' }}
          >
            <EventIcon />
          </div>
        )
      case 'News':
        return (
          <div
            className={style.iconContainer}
            style={{ background: '#FF9191' }}
          >
            <NewsIcon />
          </div>
        )
      default:
        return null
    }
  }
  return (
    <div
      className={`${style.singleCard} ${
        expanded === index ? style.expanded : style.collapsed
      } ${isDragging ? style.dragging : ''}`}
      onClick={() => handleExpand(index)}
      ref={ref}
      draggable
      onDragStart={(e) => {
        console.log('Drag started for feedItem:', feedItem);
        onDragStart(e, feedItem);
      }}
      onDragEnd={() => {
        console.log('Drag ended for feedItem:', feedItem);
        onDragEnd();
      }}
    >
      {feedIcon()}
      <div className={style.cardContent}>
        <div style={{ display: 'flex', width: '100%' }}>
          <div className={style.content}>
            <div className={style.contentContainer}>
              <p className={`${style.cardTitle} ${chatTab ? style.small : ''}`}>
                {feedItem.title}
              </p>
              <p
                className={`${style.cardSubTitle} ${
                  chatTab ? style.small : ''
                }`}
              >
                {feedItem.category === 'Jobs'
                  ? feedItem.company
                  : feedItem.summary}
                {feedItem.organizer && feedItem.organizer}
              </p>
            </div>
          </div>
          <div className={style.options}>
            {!chatTab && (
              <>
                <div className={style.saveContainer}>
                  <div onClick={handleSave} className={style.save}>
                    <SaveIcon fill={isSaved ? '#432C5B' : ''} />
                  </div>
                </div>
                <div className={style.dateContainer}>
                  {checkTime(feedItem.datePosted)}
                </div>
              </>
            )}
            <div className={style.cardBuble}>
              {!feedItem.seen && <div className={style.bubble}></div>}
            </div>
          </div>
        </div>
        <p
          className={`${
            events ? style.cardDescriptionSmall : style.cardDescription
          }`}
        >
          {feedItem.description}
        </p>
        <div className={style.buttonConatiner}>
          <div className={style.saveContainerMobile}>
            <div onClick={handleSave} className={style.save}>
              <SaveIcon fill={isSaved ? '#432C5B' : ''} />
            </div>
          </div>
          <button onClick={handleClick} className={style.buttonJobs}>
            Go to jobs page
          </button>
          {exsistingFile ? (
            <button className={style.button}>
              <FileUploadedIcon noBubble={true} />{' '}
            </button>
          ) : (
            <button onClick={handleClickAdd} className={style.button}>
              Add to chat
            </button>
          )}
        </div>
      </div>
      {exsistingFile ? (
        <div className={style.cardAdded}>
          <FileUploadedIcon />
        </div>
      ) : (
        <div className={style.cardDragExpanded}>
          <div className={style.drag}></div>
          <DragIcon />
        </div>
      )}
    </div>
  )
}

export default FeedCard
