import React from 'react';

const Roadmaps = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '670px',
        padding: '20px', // Adds padding around the entire view to prevent elements from touching the edges
      }}
    >
      {/* Header */}
      <div
        style={{
          width: '95%', // Adjust this to set the desired width of the header and container
          padding: '10px',
          backgroundColor: '#432C5B',
          color: 'white',
          fontSize: '18px',
          textAlign: 'center',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        Demo
      </div>

      {/* Container */}
      <div
        style={{
          width: '95%', // Matches the header width
          height: '80%', // Adjust height as needed
          border: '1px solid rgba(0, 0, 0, 0.1)',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          overflow: 'hidden', // Ensures nothing spills out of the container
          backgroundColor: 'white',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          marginTop: '10px', // Adds some space between the header and the container
        }}
      >
        <iframe
          style={{
            width: '100%', // Ensures the iframe takes the full width of the container
            height: '100%', // Ensures the iframe takes the full height of the container
            border: 'none',
          }}
          src="https://embed.figma.com/proto/rAv04wTP25s7eZWRnHcY1L/Untitled?page-id=0%3A1&node-id=1-1344&p=f&viewport=1109%2C194%2C0.27&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=1%3A1344&hotspot-hints=0&embed-host=share" allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};
export default Roadmaps;
