import style from './Contact.module.css'
import LocationIcon2 from '../../../assets/svgs/Location2'
import EnvelopeIcon2 from '../../../assets/svgs/Envelope2'
import MapDisplay from '../../../utils/GoogleMap'
import useWindowHeight from '../../../hooks/useWindowHeight'
import useWindowWidth from '../../../hooks/useWindowWidth'

const Contact = () => {
  const innerWidth = useWindowWidth()
  const innerHeight = useWindowHeight()
  return (
    <div className={style.mainContainer} id="contact">
      <p className={style.contactTitle}>Contact</p>
      <div className={style.container}>
        <MapDisplay
          height={innerWidth < 400 ? '280px' : innerHeight > 560 ? '150px' : ''}
          width={'100%'}
        />{' '}
        <div className={style.formContainer}>
          <div className={style.left}>
            <div className={style.contact}>
              <div className={style.iconContainer}>
                {' '}
                <LocationIcon2 />
              </div>
              <div className={style.contactInfo}>
                <p className={style.text}>Location</p>
                <p className={style.subText}>Forskningsringen 88, 174 62 Sundbyberg</p>
              </div>
            </div>
            <div className={style.contact}>
              <div className={style.iconContainer}>
                {' '}
                <EnvelopeIcon2 />
              </div>
              <div className={style.contactInfo}>
                <p className={style.text}>Mail</p>
                <p className={style.subText}>adchimp.ai@gmail.com</p>
              </div>
            </div>
          </div>
          <div className={style.right}>
            <form>
              <div className={style.inputs}>
                <div className={style.inputDiv}>
                  <input className={style.names} placeholder="Name" />
                </div>
                <div className={style.inputDiv}>
                  <input className={style.names} placeholder="Email" />
                </div>
              </div>

              <div className={style.inputDiv}>
                <input className={style.input} placeholder="Subject" />
              </div>
              <div className={style.inputDiv}>
                <input className={style.inputMessage} placeholder="Message" />
              </div>
              <div className={style.buttonContainer}>
                <button className={style.button}>Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Contact
