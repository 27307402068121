import CloseBubbleIcon from '../../../../assets/svgs/CloseBubble';
import EditIcon from '../../../../assets/svgs/EditIcon';
import style from './MainProfile.module.css';

const About = ({
  title,
  userProfile,
  openModal,
  toggleModal,
  owner,
  removeComponent,
  editMode,
}) => {
  console.log('Rendering About component with userProfile:', userProfile);

  return (
    <>
      {userProfile?.about?.length > 0 && (
        <div className={style.aboutWrapper}>
          <div className={style.header}>
            <p className={style.title}>About {title}</p>
            {owner && editMode && (
              <div style={{ display: 'flex', gap: '5px', cursor: 'pointer' }}>
                <div
                  onClick={() => {
                    !userProfile?.about?.[0]?.content
                      ? toggleModal('about', userProfile?.about?.[0], 'create')
                      : toggleModal('about', userProfile?.about?.[0]);
                  }}
                >
                  <EditIcon />
                </div>
                <div onClick={() => removeComponent('1')}>
                  <CloseBubbleIcon color="#393D63" height={20} width={20} />
                </div>
              </div>
            )}
          </div>

          <p className={style.text}>
            {userProfile?.about?.[0]?.content || 'No content available'}
          </p>
        </div>
      )}
    </>
  );
};

export default About;
