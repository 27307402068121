import style from './MainProfile.module.css';

const Suggestions = ({ userProfile, toggleModal }) => {
    const { about, skills, languages, experience, education } = userProfile;

    console.log('User profile data:', { about, skills, languages, experience, education });

    const suggestions = [];

    if (!about?.[0]?.content) {
        suggestions.push({ message: "Add an about section to let others know more about you.", modalType: 'about' });
    }
    if (!skills || skills.length === 0) {
        suggestions.push({ message: "Add some skills to showcase your expertise.", modalType: 'skills' });
    }
    if (!languages || languages.length === 0) {
        suggestions.push({ message: "Add languages you know to let others know your linguistic abilities.", modalType: 'languages' });
    }
    if (!experience || experience.length === 0) {
        suggestions.push({ message: "Add your work experience to show your professional background.", modalType: 'experience' });
    }
    if (!education || education.length === 0) {
        suggestions.push({ message: "Add your educational background.", modalType: 'education' });
    }

    const handleUpdateClick = (modalType) => {
        toggleModal(modalType, userProfile, 'create');
    };

    return (
        <div className={style.wrapper}>
            <p className={style.title}>Suggestions</p>
            <div className={style.suggestions}>
                {suggestions.length > 0 ? (
                    suggestions.map((suggestion, index) => (
                        <div key={index} className={style.suggestionCard}>
                            {suggestion.message}
                            <div className={style.suggestionButtonContainer}>
                                <button onClick={() => handleUpdateClick(suggestion.modalType)} className={style.button}>Update</button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className={style.suggestionCard}>
                        Your profile looks complete. Great job!
                    </div>
                )}
            </div>
        </div>
    );
};

export default Suggestions;
