import { useNavigate } from 'react-router-dom'
import EnvelopeIcon from '../../../assets/svgs/Envelope'
import FacebookIcon from '../../../assets/svgs/Facebook'
import FacebookIconGrad from '../../../assets/svgs/FacebookGrad'
import Icon from '../../../assets/svgs/Icon'
import LinkedinIcon from '../../../assets/svgs/Linkedin'
import LinkedinIconGrad from '../../../assets/svgs/LinkedinGrad'
import LocationIcon from '../../../assets/svgs/Location'
import PhoneIcon from '../../../assets/svgs/Phone'
import TwitterIcon from '../../../assets/svgs/Twitter'
import TwitterIconGrad from '../../../assets/svgs/TwitterGrad'
import style from './Footer.module.css'
const Footer = () => {
const navigate = useNavigate()
  const handleNavigation = (path, sectionId) => {
    navigate(path)
    setTimeout(() => {
      const section = document.getElementById(sectionId)
      if (section) section.scrollIntoView({ behavior: 'smooth' })
    }, 200)
  }
  return (
    <div className={style.mainContainer}>
      <div className={style.container}>
        <div className={style.iconsContainer}>
          <div className={style.icon}>
            <p className={style.logoText}>CHIMP</p> <Icon />
          </div>
          <div className={style.icons}>
            <div className={style.social}>
                   <a
              href="https://www.linkedin.com/company/chimpai/"
              target="_blank"
              rel="noreferrer"
              className='link2'
            >
              <TwitterIcon />
              <TwitterIconGrad/>
            </a>
            </div>
            <div className={style.social}>
              <a
                className="link"
                href="https://www.linkedin.com/company/chimpai/"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                <LinkedinIconGrad />
                <LinkedinIcon />
              </a>
            </div>
            <div className={style.social}>
              <a
              href="https://www.linkedin.com/company/chimpai/"
              target="_blank"
              rel="noreferrer"
              className="link1"
            >
              <FacebookIcon />
              <FacebookIconGrad/>
            </a>
            </div>
       
          </div>
        </div>
        <div className={style.footerNav}>
          <div className={style.navLinks}>
        
            <p
              className={style.navItem}
              onClick={() => handleNavigation('/', '')}
            >
              Home
            </p>
            <p
              className={style.navItem}
              onClick={() => handleNavigation('/', 'about')}
            >
              About
            </p>
            <p
              className={style.navItem}
              onClick={() => handleNavigation('/', 'offerings')}
            >
              Offerings
            </p>
            <p
              className={style.navItem}
              onClick={() => handleNavigation('/', 'contact')}
            >
              Contact
            </p>
          </div>

          <div className={style.footerContactsContainer}>
            <p className={style.contactMobileText}>Contact us</p>
            <div className={style.contact}>
              <LocationIcon />
              <p className={style.contactText}>Forskningsringen 88, 174 62 Sundbyberg</p>
            </div>
            <div className={style.contact}>
              <PhoneIcon />
              <p className={style.contactText}>(+46) 764388716</p>
            </div>
            <div className={style.contact}>
              <EnvelopeIcon />
              <a
                className={style.contactText}
                href="mailto:adchimp.ai@gmail.com"
              >
                adchimp.ai@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Footer
