import { useEffect, useState } from 'react'
import style from './Feed.module.css'
import FeedCard from './FeedCard'
import FilterIcon from '../../../assets/svgs/Filter'
import useDroppable from '../../../hooks/useDroppable'
import { FeedData } from '../../../dummyData'
const Feed = () => {
  
  const filters = [
    'All',
    'Jobs',
    'Courses',
    'Contacts',
    'Events',
    'News',
    'Chimp',
    'Saved'
  ]

  const [selectedFilters, setSelectedFilters] = useState([])
  const [feed, setFeed] = useState(FeedData)
  const [openFilter, setOpenFilter] = useState(false)
  const droppableProps = useDroppable(feed, setFeed);

  // Log the initial feed data
  useEffect(() => {
    console.log('Initial FeedData:', FeedData);
    console.log('Initial feed state:', feed);
  }, []);
  useEffect(() => {
    console.log('useEffect triggered with selectedFilters:', selectedFilters, 'feed:', feed);
    if (selectedFilters.length === 0) {
      console.log('No filters selected, defaulting to "All".');
      setSelectedFilters(['All'])
    }
  }, [selectedFilters , feed])

  const handleSelect = (filter) => {
    console.log('Selected filter:', filter);
    if (filter === 'All') {
      setSelectedFilters([])
    } else {
      const index = selectedFilters.indexOf(filter)
      if (index === -1) {
        if (selectedFilters.includes('All')) {
          setSelectedFilters([filter])
        } else {
          setSelectedFilters([...selectedFilters, filter])
        }
      } else {
        setSelectedFilters(selectedFilters.filter((f) => f !== filter))
      }
    }
    console.log('Updated selected filters:', selectedFilters);
  }

  const isFiltered = (item) => {
    const result =
      selectedFilters.length === 0 ||
      selectedFilters.includes('All') ||
      selectedFilters.includes(item.category);
    console.log('Item filtered result:', item, result);
    return result;
  };

  useEffect(() => {
    console.log('Updated feed state:', feed);
  }, [feed]);
  // Function to check if a date is today
  const isToday = (someDate) => {
    const today = new Date()
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    )
  }

  // Function to check if a date is yesterday
  const isYesterday = (someDate) => {
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    return (
      someDate.getDate() === yesterday.getDate() &&
      someDate.getMonth() === yesterday.getMonth() &&
      someDate.getFullYear() === yesterday.getFullYear()
    )
  }

  // Function to check if a date is within the current week
  const isThisWeek = (someDate) => {
    const today = new Date()
    const firstDayOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    )
    const lastDayOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + (6 - today.getDay())
    )
    const isFirstDayOfWeekOrEarlier = someDate <= firstDayOfWeek
    const isLastDayOfWeekOrLater = someDate >= lastDayOfWeek
    const isTodayOrYesterday = isToday(someDate) || isYesterday(someDate)
    return (
      !isTodayOrYesterday &&
      !isFirstDayOfWeekOrEarlier &&
      !isLastDayOfWeekOrLater
    )
  }
  const isThisMonth = (someDate) => {
    const today = new Date()
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    )
    const isFirstDayOfMonthOrEarlier = someDate <= firstDayOfMonth
    const isLastDayOfMonthOrLater = someDate >= lastDayOfMonth
    const isThiWeek =
      isToday(someDate) || isYesterday(someDate) || isThisWeek(someDate)
    return !isThiWeek && !isFirstDayOfMonthOrEarlier && !isLastDayOfMonthOrLater
  }
  const isEarlierThisYear = (someDate) => {
    const today = new Date()
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1)
    const lastDayOfYear = new Date(today.getFullYear(), 11, 31)
    return (
      someDate >= firstDayOfYear &&
      someDate <= lastDayOfYear &&
      !isThisMonth(someDate)
    )
  }

  return (
    <>
      <div className={style.container}>
        <>
          <p className={style.title}>Feed</p>
          <div className={style.filterContainer}>
            {filters.map((filter, index) => (
              <div
                className={`${style.filter} ${
                  selectedFilters.includes(filter) ? style.selected : ''
                }`}
                key={index}
                onClick={() => {filter === 'All' ? handleSelect('All') : handleSelect(filter)}}
              >
                {filter}
              </div>
            ))}
          </div>
          <div
            onClick={() => setOpenFilter(!openFilter)}
            className={style.mobileFilters}
          >
            {openFilter ? '' : <p className={style.filterTitle}>Filter</p>}
            {selectedFilters.length > 0 && !selectedFilters.includes('All') ? (
              <div className={style.filterBubble}>{selectedFilters.length}</div>
            ) : (
              null
            )}
            <FilterIcon />
          </div>
          {openFilter && (
            <div className={style.filterContainerMobile}>
              {filters.map((filter, index) => (
                <div
                  className={`${style.filter} ${
                    selectedFilters.includes(filter) ? style.selected : ''
                  }`}
                  key={index}
                  onClick={() => {filter === 'All' ? handleSelect('All') : handleSelect(filter)}}
                >
                  {filter}
                </div>
              ))}
            </div>
          )}
        </>
        <div className={style.cardsContainer}>
          {console.log('Checking for Today items:', feed.some((item) => isToday(new Date(item.datePosted))))}
          {feed.some((item) => isToday(new Date(item.datePosted))) && (
            <div className={style.filteredFeed}>
              <p className={style.timetext}>Today:</p>
              {feed
                .filter((item) => {
                  const isTodayItem = isToday(new Date(item.datePosted));
                  console.log('Filtering for Today, item:', item, 'isToday:', isTodayItem);
                  return isTodayItem;
                })
                .map(
                  (feedItem, index) => {
                    console.log('Filtering item:', feedItem, 'isFiltered:', isFiltered(feedItem));
                    return (
                      isFiltered(feedItem) && (
                        <div
                          key={index}
                          onDrop={(e) => droppableProps.onDrop(e, index)}
                          onDragOver={droppableProps.onDragOver}
                        >
                          <FeedCard
                            feedItem={feedItem}
                            index={index}
                          />
                        </div>
                      )
                    );
                  }
                )}
            </div>
          )}
          {console.log('Checking for Yesterday items:', feed.some((item) => isYesterday(new Date(item.datePosted))))}
          {feed.some((item) => isYesterday(new Date(item.datePosted))) && (
            <div className={style.filteredFeed}>
              <p className={style.timetext}>Yesterday:</p>
              {feed
                .filter((item) => {
                  const isYesterdayItem = isYesterday(new Date(item.datePosted));
                  console.log('Filtering for Yesterday, item:', item, 'isYesterday:', isYesterdayItem);
                  return isYesterdayItem;
                })
                .map(
                  (feedItem, index) => {
                    console.log('Filtering item:', feedItem, 'isFiltered:', isFiltered(feedItem));
                    return (
                      isFiltered(feedItem) && (
                        <div
                          key={index}
                          onDrop={(e) => droppableProps.onDrop(e, index)}
                          onDragOver={droppableProps.onDragOver}
                        >
                          <FeedCard
                            feedItem={feedItem}
                            index={index}
                          />
                        </div>
                      )
                    );
                  }
                )}
            </div>
          )}
          {console.log('Checking for This Week items:', feed.some((item) => isThisWeek(new Date(item.datePosted))))}
          {feed.some((item) => isThisWeek(new Date(item.datePosted))) && (
            <div className={style.filteredFeed}>
              <p className={style.timetext}>This Week:</p>
              {feed
                .filter((item) => {
                  const isThisWeekItem = isThisWeek(new Date(item.datePosted));
                  console.log('Filtering for This Week, item:', item, 'isThisWeek:', isThisWeekItem);
                  return isThisWeekItem;
                })
                .map(
                  (feedItem, index) => {
                    console.log('Filtering item:', feedItem, 'isFiltered:', isFiltered(feedItem));
                    return (
                      isFiltered(feedItem) && (
                        <div
                          key={index}
                          onDrop={(e) => droppableProps.onDrop(e, index)}
                          onDragOver={droppableProps.onDragOver}
                        >
                          <FeedCard
                            feedItem={feedItem}
                            index={index}
                          />
                        </div>
                      )
                    );
                  }
                )}
            </div>
          )}
          {console.log('Checking for This Month items:', feed.some((item) => isThisMonth(new Date(item.datePosted))))}
          {feed.some((item) => isThisMonth(new Date(item.datePosted))) && (
            <div className={style.filteredFeed}>
              <p className={style.timetext}>This Month:</p>
              {feed
                .filter((item) => {
                  const isThisMonthItem = isThisMonth(new Date(item.datePosted));
                  console.log('Filtering for This Month, item:', item, 'isThisMonth:', isThisMonthItem);
                  return isThisMonthItem;
                })
                .map(
                  (feedItem, index) => {
                    console.log('Filtering item:', feedItem, 'isFiltered:', isFiltered(feedItem));
                    return (
                      isFiltered(feedItem) && (
                        <div
                          key={index}
                          onDrop={(e) => droppableProps.onDrop(e, index)}
                          onDragOver={droppableProps.onDragOver}
                        >
                          <FeedCard
                            feedItem={feedItem}
                            index={index}
                          />
                        </div>
                      )
                    );
                  }
                )}
            </div>
          )}
          {console.log('Checking for Earlier This Year items:', feed.some((item) => isEarlierThisYear(new Date(item.datePosted))))}
          {feed.some((item) =>
            isEarlierThisYear(new Date(item.datePosted))
          ) && (
            <div className={style.filteredFeed}>
              <p className={style.timetext}>Earlier This Year:</p>
              {feed
                .filter((item) => {
                  const isEarlierThisYearItem = isEarlierThisYear(new Date(item.datePosted));
                  console.log('Filtering for Earlier This Year, item:', item, 'isEarlierThisYear:', isEarlierThisYearItem);
                  return isEarlierThisYearItem;
                })
                .map(
                  (feedItem, index) => {
                    console.log('Filtering item:', feedItem, 'isFiltered:', isFiltered(feedItem));
                    return (
                      isFiltered(feedItem) && (
                        <div
                          key={index}
                          onDrop={(e) => droppableProps.onDrop(e, index)}
                          onDragOver={droppableProps.onDragOver}
                        >
                          <FeedCard
                            feedItem={feedItem}
                            index={index}
                          />
                        </div>
                      )
                    );
                  }
                )}
            </div>
          )}
        </div>

        <p className={style.scrollText}>Scroll to see more</p>
      </div>
    </>
  );
}

export default Feed
